import { Flex, List, ListItem } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { MdAdd } from 'react-icons/md';
import { Center } from '@chakra-ui/react';

function CreateItemOption({
  getItemProps,
  index,
  highlightedIndex,
  addItemLabel,
  inputValue,
  valueIsValid,
  loadedItems,
}) {
  const inputValueIsValidNewItem = useMemo(() => {
    if (typeof inputValue === 'undefined' || inputValue.trim().length === 0) {
      return false;
    }

    for (let loadedItem of loadedItems) {
      if (loadedItem.label === inputValue) {
        return false;
      }
    }

    if (!valueIsValid) {
      return true;
    }

    return valueIsValid(inputValue);
  }, [inputValue, loadedItems]);

  if (!inputValueIsValidNewItem) {
    return null;
  }

  return (
    <ListItem
      {...getItemProps({
        item: {
          label: inputValue,
          isAddOnTheFly: true,
        },
        index,
        bg: highlightedIndex === index ? 'primary' : 'transparent',
        color: highlightedIndex === index ? 'light' : null,
      })}
      key={'add-new-item'}
      py={1}
      px={2}
      cursor="pointer"
    >
      <Flex>
        <Center>
          <MdAdd />
          <span>
            {addItemLabel} "{inputValue}"
          </span>
        </Center>
      </Flex>
    </ListItem>
  );
}

export default function LoadedItemList({
  getMenuProps,
  loadedItems,
  getItemProps,
  highlightedIndex,
  hasMoreItems,
  loadNextItems,
  currentPage,
  loadingMore,
  onItemCreation,
  valueIsValid,
  inputValue,
  addItemLabel,
  size,
  selectedItem,
  maxHeightList,
}) {
  const menuRef = useRef();

  const menuProps = getMenuProps({
    ref: (e) => {
      menuRef.current = e;
    },
  });

  useLayoutEffect(() => {
    if (!loadingMore) {
      return;
    }

    menuRef.current.scrollTop =
      menuRef.current.scrollHeight - menuRef.current.offsetHeight;
  }, [loadingMore, menuRef.current]);

  useEffect(() => {
    const menuElement = menuRef.current;

    if (!menuElement || !hasMoreItems || loadingMore) {
      return;
    }
    const scrollListener = () => {
      if (
        menuElement.scrollTop >=
        menuElement.scrollHeight - menuElement.offsetHeight
      ) {
        loadNextItems(currentPage + 1);
      }
    };

    menuElement.addEventListener('scroll', scrollListener);

    return () => {
      if (!menuElement) {
        return;
      }

      menuElement.removeEventListener('scroll', scrollListener);
    };
  }, [menuRef.current, hasMoreItems, loadingMore]);

  let noResult = null;

  if (loadedItems.length === 0) {
    noResult = (
      <ListItem
        {...menuProps}
        bg="secondary"
        key={'add-new-item'}
        py={1}
        px={2}
        cursor="default"
      >
        Aucun résultat trouvé
      </ListItem>
    );
  }

  return (
    <>
      <List
        {...menuProps}
        style={{
          maxHeight: maxHeightList,
          overflowY: 'auto',
        }}
        bg="light"
        borderWidth={1}
        borderColor="lightGrey"
        position="absolute"
        w="100%"
        zIndex="dropdown"
        fontSize={() => {
          if (size === 'xs') {
            return '.75rem';
          }
          if (size === 'sm') {
            return '.875rem';
          }
          return '1em';
        }}
      >
        {loadedItems.map((loadedItem, index) => {
          let bg = 'transparent';
          let color = null;
          if (
            selectedItem &&
            selectedItem.id &&
            loadedItem?.id === selectedItem?.id
          ) {
            bg = 'primary';
            color = 'light';
          }
          if (highlightedIndex === index) {
            bg = 'primaryLighter';
            color = 'light';
          }

          return (
            <ListItem
              {...getItemProps({
                item: loadedItem,
                index,
                bg,
                color,
              })}
              key={loadedItem.id}
              py={1}
              px={2}
              cursor="pointer"
            >
              {loadedItem.label}
            </ListItem>
          );
        })}

        {noResult}

        {!hasMoreItems && onItemCreation && (
          <CreateItemOption
            loadedItems={loadedItems}
            onItemCreation={onItemCreation}
            valueIsValid={valueIsValid}
            inputValue={inputValue}
            index={loadedItems.length}
            highlightedIndex={highlightedIndex}
            getItemProps={getItemProps}
            addItemLabel={addItemLabel}
          />
        )}

        {loadingMore && (
          <Flex
            bg="secondary"
            color="dark"
            py={1}
            px={2}
            position="absolute"
            w="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <span>chargement en cours</span>
            <Spinner size="xs" />
          </Flex>
        )}
      </List>
    </>
  );
}
