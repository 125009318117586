import Downshift from 'downshift';
import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  forwardRef,
  Input,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import DownShiftItemList from './DownShiftItemList';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { VscChromeClose } from 'react-icons/vsc';
import FormErrors from '../FormErrors';

const ForwardedInput = forwardRef((props, ref) => {
  return (
    <Input
      ref={ref}
      {...props}
      borderRightRadius={0}
      fontSize={props.size}
      bg="white"
      cursor="default"
      borderColor={props.borderColor ? props.borderColor : null}
    />
  );
});

export default function DownShiftSelect({
  selectedItem,
  id,
  label,
  onChange,
  placeholder,
  loadSelectItems,
  onBlur,
  errors,
  size,
  valueIsValid,
  onItemCreation,
  addItemLabel,
  allowClear,
  mb,
  mr,
  disabled,
  allowTextQuery,
  borderColor,
  onOuterClick,
  initialIsOpen,
  onMenuOpen,
  onMenuClose,
  maxHeightList,
}) {
  const rootRef = useRef();

  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef();

  const xsButtonWidth = '1.5rem';
  const smButtonWidth = '2.25rem';
  const mdButtonWidth = '2.75rem';

  // Calculate selectedTextWidth in CSS:
  // width: calc(100% - caret button width - allowclear btn width)
  const selectedTextWidth = function (size, allowClear) {
    let buttonWidth = mdButtonWidth;
    if (size === 'xs') {
      buttonWidth = xsButtonWidth;
    }
    if (size === 'sm') {
      buttonWidth = smButtonWidth;
    }
    if (allowClear === true) {
      return 'calc(100% - (2 * ' + buttonWidth + '))';
    }
    return 'calc(100% - ' + buttonWidth + ')';
  };
  const buttonWidthCSS = function (size) {
    let buttonWidth = mdButtonWidth;
    if (size === 'xs') {
      buttonWidth = xsButtonWidth;
    }
    if (size === 'sm') {
      buttonWidth = smButtonWidth;
    }
    return buttonWidth;
  };

  useOutsideClick({
    ref: rootRef,
    handler: async () => {
      if (onOuterClick) {
        onOuterClick();
      }
    },
    enabled: typeof onOuterClick !== 'undefined',
  });

  return (
    <Downshift
      onStateChange={(changes) => {
        if (typeof changes.isOpen !== 'undefined') {
          if (changes.isOpen && onMenuOpen) {
            onMenuOpen();
            return;
          }
          if (onMenuClose) {
            onMenuClose();
          }
        }
      }}
      onOuterClick={onOuterClick}
      initialIsOpen={initialIsOpen}
      inputValue={inputValue}
      onInputValueChange={(inputValue) => {
        if (!allowTextQuery) {
          return;
        }

        // when using a label as a react component (budget item normalizer having an icon for example), we receieve the label as input value
        // this input value is directly reset, but it still triggers an error if we set this value for the input value
        // this is kind of a hack and a better solution should be found later if someone want to look at it
        if (React.isValidElement(inputValue)) {
          return;
        }

        setInputValue(inputValue);
      }}
      id={`downshift-${id}`}
      inputId={`downshift-input-${id}`}
      onSelect={(item, downshift) => {
        if (item?.isAddOnTheFly) {
          onItemCreation(item.label);
          inputRef.current.blur();
          return;
        }

        if (item !== selectedItem) {
          onChange(item);
        }
        inputRef.current.blur();
      }}
      itemToString={(item) => {
        if (!item) {
          return '';
        }

        return item.label;
      }}
      defaultHighlightedIndex={0}
      selectedItem={null}
    >
      {({
        getLabelProps,
        getInputProps,
        getItemProps,
        isOpen,
        closeMenu,
        openMenu,
        highlightedIndex,
        inputValue,
        clearItems,
        getMenuProps,
        getRootProps,
      }) => {
        return (
          <FormControl
            mb={mb}
            {...getRootProps({ ref: rootRef }, { suppressRefError: true })}
            textStyle="bodyFont"
            isInvalid={errors}
            size={'190ch'}
            mr={mr}
          >
            {label && (
              <FormLabel
                {...getLabelProps()}
                fontSize={size}
                disabled={disabled}
                mb={() => {
                  if (size === 'xs') {
                    return 0;
                  }
                  if (size === 'sm') {
                    return 2;
                  }
                  return 4;
                }}
              >
                {label}
              </FormLabel>
            )}
            <Flex position="relative" fontSize={size}>
              {selectedItem && inputValue.length === 0 && (
                <Box
                  onClick={() => {
                    inputRef.current.focus();
                  }}
                  position="absolute"
                  pl={() => {
                    if (size === 'xs') {
                      return 2;
                    }
                    if (size === 'sm') {
                      return 3;
                    }
                    return 4;
                  }}
                  h="auto"
                  lineHeight="2.5em"
                  width={selectedTextWidth(size, allowClear)}
                  zIndex={3}
                  cursor={disabled ? 'not-allowed' : 'pointer'}
                  userSelect="none"
                  opacity={disabled ? '0.4' : undefined}
                >
                  <Text
                    title={
                      selectedItem.title
                        ? selectedItem.title
                        : selectedItem.label
                    }
                    isTruncated
                    lineHeight={() => {
                      if (size === 'xs') {
                        return 7;
                      }
                      if (size === 'sm') {
                        return 8;
                      }
                      return 10;
                    }}
                  >
                    {selectedItem.label}
                  </Text>
                </Box>
              )}
              <ForwardedInput
                {...getInputProps({
                  ref: inputRef,
                  placeholder: selectedItem ? undefined : placeholder,
                  disabled,
                  onFocus: (evt) => {
                    if (disabled) {
                      return;
                    }
                    openMenu();
                  },
                  onBlur: (evt) => {
                    closeMenu();

                    setInputValue('');
                    if (onBlur) {
                      onBlur();
                    }
                  },
                  onKeyDown: (evt) => {
                    if (
                      (evt.key === 'Backspace' || evt.key === 'Delete') &&
                      !evt.target.value &&
                      allowClear
                    ) {
                      onChange(null);
                    }
                  },
                })}
                borderRight="none"
                size={size}
                color={allowTextQuery ? undefined : 'transparent'}
                borderColor={borderColor}
              />
              {selectedItem && allowClear && (
                <Button
                  disabled={disabled}
                  onClick={(evt) => {
                    evt.preventDefault();
                    onChange(null);
                    clearItems();
                  }}
                  variant="outline"
                  borderRadius={0}
                  borderLeft="none"
                  size={size}
                  pl={0}
                  pr={0}
                  minWidth={buttonWidthCSS(size)}
                  maxWidth={buttonWidthCSS(size)}
                  bg="white"
                  borderColor={borderColor}
                >
                  <VscChromeClose />
                </Button>
              )}
              <Button
                onClick={(evt) => {
                  evt.preventDefault();
                  inputRef.current.focus();
                }}
                disabled={disabled}
                variant="primary"
                borderLeftRadius={0}
                size={size}
                borderRightRadius={() => {
                  if (size === 'xs') {
                    return '.125em';
                  }
                  if (size === 'sm') {
                    return '.25em';
                  }
                  return '.375em';
                }}
                pl={0}
                pr={0}
                minWidth={buttonWidthCSS(size)}
                maxWidth={buttonWidthCSS(size)}
              >
                <span>
                  {isOpen ? (
                    <ChevronUpIcon w="1.25em" h="1.25em" />
                  ) : (
                    <ChevronDownIcon w="1.25em" h="1.25em" />
                  )}
                </span>
              </Button>
            </Flex>

            {isOpen && (
              <DownShiftItemList
                getItemProps={getItemProps}
                highlightedIndex={highlightedIndex}
                inputValue={inputValue}
                getMenuProps={getMenuProps}
                loadSelectItems={loadSelectItems}
                onItemCreation={onItemCreation}
                valueIsValid={valueIsValid}
                addItemLabel={addItemLabel}
                size={size}
                selectedItem={selectedItem}
                maxHeightList={maxHeightList}
              />
            )}

            <FormErrorMessage mt={1}>
              <FormErrors errors={errors} />
            </FormErrorMessage>
          </FormControl>
        );
      }}
    </Downshift>
  );
}

DownShiftSelect.defaultProps = {
  allowClear: false,
  mb: 2,
  allowTextQuery: true,
  maxHeightList: '10em',
};
