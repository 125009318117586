import useApiClient from '../../../common/hooks/apiClient';
import DownShiftSelect from '../../../common/components/Form/DownShift/DownShiftSelect';
import { loadSelectItemsFromApi } from '../../../common/components/Form/DownShift/DownShiftItemList';
import React from 'react';

function normalizer(hotel) {
  return {
    id: hotel.id,
    label: hotel.name,
  };
}

export default function HotelSelect(props) {
  const apiClient = useApiClient();
  const { filters, includes, ...otherProps } = props;

  return (
    <DownShiftSelect
      label="Hotel"
      placeholder="Rechercher un hotel"
      maxHeightList={'22em'}
      loadSelectItems={function (abortController, inputValue, page) {
        return loadSelectItemsFromApi(
          abortController,
          inputValue,
          page,
          '/api/hotels/',
          apiClient,
          normalizer,
          filters,
          includes
        );
      }}
      {...otherProps}
    />
  );
}
