import Header from './Header';
import Menu from './Menu';
import { Box, Flex } from '@chakra-ui/layout';
import { MENU_WIDTH } from '../appTheme';
import { React } from 'react';
import Head from 'next/head';
import RightPage from './RightPage';

export default function BasePageLayout(props) {
  return (
    <>
      <Head>
        <title>{props.pageTitle}</title>
      </Head>

      <Flex
        minH="100vh"
        bg="light"
        color="dark"
        m="0 auto"
        textStyle="bodyFont"
      >
        <Menu activeMenuTab={props.activeMenuTab} />
        <Flex
          flexDirection="column"
          flexGrow={1}
          maxWidth={'calc(100% - ' + MENU_WIDTH + ')'}
          color="primary"
        >
          <Header pageTitle={props.pageTitle} header={props.header} />
          <Flex flexGrow={1}>
            <Box
              ref={props.scrollableZoneRef}
              bg="light"
              pl={1}
              pr={1}
              overflowY="auto"
              maxHeight={'calc(100vh - ' + props.headerHeight + 'px)'}
              flexGrow={1}
              pos="sticky"
              top={props.headerHeight + 'px'}
              zIndex={1}
            >
              {props.children}
            </Box>
            <RightPage />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
