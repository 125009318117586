import { Center, Flex, Image, Link, Tooltip } from '@chakra-ui/react';
import {
  MdAccountBalance,
  MdAssignment,
  MdContacts,
  MdDomain,
  MdFormatListBulletedAdd,
  MdLineWeight,
  MdViewList,
} from 'react-icons/md';
import { AiFillCalculator, AiOutlineLink } from 'react-icons/ai';
import { React, useMemo } from 'react';
import OversightSigleGreen from '../../assets/img/oversight-sigle-green.svg';
import CurrentHotelLink from '../../core/components/CurrentHotelLink';
import { HEADER_MIN_HEIGHT, MENU_WIDTH } from '../appTheme';
import { useCurrentHotel } from '../hooks/currentHotel';
import getConfig from 'next/config';
import { useCurrentUser } from '../hooks/authenticationStatus';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import GrCompare from '../../core/components/Icons/GrCompare';

export const MENU_TAB_CONTRACT = 'contract';
export const MENU_TAB_THIRD_PARTY = 'thirdParty';
export const MENU_TAB_PROVISIONAL_PLAN = 'provisionalPlan';
export const MENU_TAB_PLAN_LINE = 'planLine';
export const MENU_TAB_CALCULATION_BASIS = 'calculationBasis';
export const MENU_TAB_LINK_ENGINE = 'linkEngine';
export const MENU_TAB_CASH_PLAN = 'cashPlan';
export const MENU_TAB_INCOME_STATEMENT = 'incomeStatement';
export const MENU_TAB_UPDATED_PLAN = 'updatedPlan';
export const MENU_TAB_CONSOLIDATED_PLAN = 'consolidatedPlan';
export const MENU_TAB_EXTHAND = 'exthand';

function MenuItem({ isActive, href, label, icon }) {
  const IconComponent = icon;

  const menuItem = (
    <Center
      cursor="pointer"
      as="a"
      fontSize="1.5em"
      my={8}
      color={isActive ? 'secondary' : undefined}
    >
      <Tooltip label={label} aria-label={label} hasArrow placement="right">
        <span>
          <IconComponent />
        </span>
      </Tooltip>
    </Center>
  );

  if (!href) {
    return menuItem;
  }

  return <CurrentHotelLink href={href}>{menuItem}</CurrentHotelLink>;
}

export default function Menu(props) {
  const hotel = useCurrentHotel();
  const user = useCurrentUser();

  const isAdmin = useMemo(
    () => user.roles.indexOf('ROLE_OVERSIGHT') !== -1,
    [user]
  );
  const dashboardLabel = `Tableau de bord ${hotel?.name}`;

  return (
    <Flex
      bg="primary"
      color="light"
      px={1}
      direction="column"
      w={MENU_WIDTH}
      flexShrink={0}
    >
      <div>
        <CurrentHotelLink href="/">
          <Center as="a" href="#" h={HEADER_MIN_HEIGHT} mx={2}>
            <Tooltip
              label={dashboardLabel}
              aria-label={dashboardLabel}
              hasArrow
              placement="right"
            >
              <Image src={OversightSigleGreen} alt="" />
            </Tooltip>
          </Center>
        </CurrentHotelLink>
        <MenuItem
          href="/contrats"
          isActive={props.activeMenuTab === MENU_TAB_CONTRACT}
          icon={MdAssignment}
          label="Contrats"
        />
        <MenuItem
          href="/tiers"
          isActive={props.activeMenuTab === MENU_TAB_THIRD_PARTY}
          icon={MdContacts}
          label="Tiers"
        />
        {isAdmin && (
          <>
            <MenuItem
              href="/ligne-plan"
              isActive={props.activeMenuTab === MENU_TAB_PLAN_LINE}
              icon={MdLineWeight}
              label="Lignes de Plan"
            />
            <MenuItem
              href="/bases-de-calcul"
              isActive={props.activeMenuTab === MENU_TAB_CALCULATION_BASIS}
              icon={AiFillCalculator}
              label="Bases de Calcul"
            />
            <MenuItem
              href="/plan-previsionels"
              isActive={props.activeMenuTab === MENU_TAB_PROVISIONAL_PLAN}
              icon={MdViewList}
              label="Plan prévisionels"
            />
            <MenuItem
              href={'/moteur-association'}
              isActive={props.activeMenuTab === MENU_TAB_LINK_ENGINE}
              icon={AiOutlineLink}
              label="Moteur d'association"
            />
            <MenuItem
              isActive={props.activeMenuTab === MENU_TAB_INCOME_STATEMENT}
              icon={MdAccountBalance}
              label="Compte de résultat"
              href={'/compte-resultat'}
            />
            <MenuItem
              icon={FaFileInvoiceDollar}
              label="Plan de trésorerie"
              isActive={props.activeMenuTab === MENU_TAB_CASH_PLAN}
              href={'/plan-tresorerie'}
            />
            <MenuItem
              icon={GrCompare}
              label="Plan actualisé"
              isActive={props.activeMenuTab === MENU_TAB_UPDATED_PLAN}
              href={'/plan-actualise'}
            />
            <MenuItem
              icon={MdFormatListBulletedAdd}
              label="Plan consolidé"
              isActive={props.activeMenuTab === MENU_TAB_CONSOLIDATED_PLAN}
              href={'/plan-consolide'}
            />
            <MenuItem
              icon={MdDomain}
              label="Exthand"
              isActive={props.activeMenuTab === MENU_TAB_EXTHAND}
              href={'/exthand'}
            />
          </>
        )}
      </div>
    </Flex>
  );
}
