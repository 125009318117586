import { useEffect, useReducer } from 'react';
import LoadedItemList from './LoadedItemList';
import { stringify } from 'qs';
import { Flex } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';

export const ITEMS_PER_LIST = 15;

export async function loadSelectItemsFromApi(
  abortController,
  inputValue,
  page = 1,
  url,
  apiClient,
  normalizer,
  filters = {},
  includes,
  orders
) {
  let baseOptions = {};
  if (abortController) {
    baseOptions.signal = abortController.signal;
  }

  if (abortController && abortController.signal.aborted) {
    return;
  }

  if (typeof filters === 'function') {
    filters = filters();
  }

  let queryString = {
    page: page,
    items: ITEMS_PER_LIST,
    countTotalResults: '1',
    filters: {
      ...filters,
      textQuery: inputValue,
    },
  };

  if (includes) {
    queryString.includes = includes.join(',');
  }

  if (orders) {
    queryString.orders = orders.join(',');
  }

  let searchResult = null;
  try {
    searchResult = await apiClient(
      `${url}?${stringify(queryString)}`,
      baseOptions
    ).then((response) => response.json());
  } catch (error) {
    if (error?.name === 'AbortError') {
      // silently catch abort error error since aborted request are treated as error
      return;
    }

    throw error;
  }

  const loadedItems = searchResult.items.map((item) => ({
    item,
    ...normalizer(item),
  }));

  return [loadedItems, searchResult.resultNumber];
}

const initialItemListState = {
  loading: true,
  loadingMore: false,
  loadedItems: [],
  currentPage: 1,
  pageNumber: 1,
};

const LOADING_ITEMS = 'LOADING_ITEMS';
const ITEMS_LOADED = 'ITEMS_LOADED';
const LOADING_MORE_ITEMS = 'LOADING_MORE_ITEMS';
const LOADED_MORE_ITEMS = 'LOADED_MORE_ITEMS';

function itemListReducer(state, action) {
  switch (action.type) {
    case LOADING_ITEMS:
      return { ...initialItemListState };
    case ITEMS_LOADED:
      return {
        loading: false,
        loadedItems: action.loadedItems,
        currentPage: 1,
        pageNumber: action.pageNumber,
      };
    case LOADING_MORE_ITEMS:
      return {
        ...state,
        loadingMore: true,
        currentPage: action.pageNumber,
      };
    case LOADED_MORE_ITEMS:
      return {
        ...state,
        loadingMore: false,
        loadedItems: [...state.loadedItems, ...action.loadedItems],
      };
  }

  return state;
}

export default function DownShiftItemList({
  getItemProps,
  highlightedIndex,
  inputValue,
  getMenuProps,
  loadSelectItems,
  onItemCreation,
  valueIsValid,
  addItemLabel,
  size,
  selectedItem,
  onMenuClose,
  maxHeightList,
}) {
  const [state, dispatch] = useReducer(itemListReducer, initialItemListState);

  useEffect(() => {
    if (state.currentPage === 1) {
      return;
    }

    let abortController = null;
    if (typeof AbortController !== 'undefined') {
      abortController = new AbortController();
    }

    async function loadMoreSelectItems() {
      const result = await loadSelectItems(
        abortController,
        inputValue,
        state.currentPage
      );
      if (!result) {
        return;
      }

      const [loadedItems] = result;
      dispatch({
        type: LOADED_MORE_ITEMS,
        loadedItems,
      });
    }

    loadMoreSelectItems();

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [state.currentPage]);

  useEffect(() => {
    let abortController = null;
    if (typeof AbortController !== 'undefined') {
      abortController = new AbortController();
    }

    async function loadSelectItemsFirstPage() {
      const result = await loadSelectItems(abortController, inputValue, 1);

      if (!result) {
        return;
      }

      if (abortController?.signal?.aborted) {
        return;
      }

      const [loadedItems, resultNumber] = result;

      dispatch({
        type: ITEMS_LOADED,
        loadedItems,
        pageNumber: Math.ceil(resultNumber / ITEMS_PER_LIST),
      });
    }

    dispatch({ type: LOADING_ITEMS });
    loadSelectItemsFirstPage();

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [inputValue]);

  if (state.loading) {
    return (
      <Flex
        bg="secondary"
        color="dark"
        py={1}
        px={2}
        position="absolute"
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        zIndex="dropdown"
        fontSize={() => {
          if (size === 'xs') {
            return '.75rem';
          }
          if (size === 'sm') {
            return '.875rem';
          }
          return '1em';
        }}
      >
        <span>chargement en cours</span>
        <Spinner size="xs" />
      </Flex>
    );
  }

  return (
    <LoadedItemList
      onItemCreation={onItemCreation}
      valueIsValid={valueIsValid}
      getMenuProps={getMenuProps}
      inputValue={inputValue}
      getItemProps={getItemProps}
      loadedItems={state.loadedItems}
      highlightedIndex={highlightedIndex}
      hasMoreItems={
        state.pageNumber !== 0 && state.currentPage !== state.pageNumber
      }
      currentPage={state.currentPage}
      loadingMore={state.loadingMore}
      addItemLabel={addItemLabel}
      loadNextItems={(pageNumber) => {
        dispatch({ type: LOADING_MORE_ITEMS, pageNumber });
      }}
      size={size}
      selectedItem={selectedItem}
      maxHeightList={maxHeightList}
    />
  );
}
