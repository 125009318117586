import { Box, Flex } from '@chakra-ui/layout';
import { HEADER_MIN_HEIGHT, MENU_WIDTH, RIGHT_PAGE_WIDTH } from '../appTheme';
import { Button, Tooltip } from '@chakra-ui/react';
import { RiCloseCircleLine } from 'react-icons/ri';
import { React } from 'react';
import { useCloseRightPage, useRightPageState } from '../hooks/rightPage';

export default function RightPage() {
  const { component, pageProps, pageTitle, number } = useRightPageState();
  const closeRightPage = useCloseRightPage();

  const RightPageComponent = component;

  if (!RightPageComponent) {
    return null;
  }

  return (
    <Flex
      width={['calc(100vw - ' + MENU_WIDTH + ')', null, RIGHT_PAGE_WIDTH]}
      h={'calc(100vh - ' + HEADER_MIN_HEIGHT + ')'}
      bg="light"
      position={['absolute', null, 'revert']}
      boxShadow="xl"
      overflow="hidden"
      flexDirection="column"
      zIndex={3} /* i.e. above Chakra InputLeftElement */
      flexShrink={0}
    >
      <Flex
        fontWeight="bold"
        mb={2}
        pl={4}
        py={2}
        background="primary"
        color="light"
        justifyContent="space-between"
        borderTopRightRadius={6}
        borderTopLeftRadius={6}
      >
        <Box fontSize="sm">{pageTitle}</Box>
        <Button
          variant="link"
          fontSize="1.25em"
          color="light"
          _hover={{ color: 'secondary' }}
          onClick={closeRightPage}
        >
          <Tooltip
            label="Fermer le détail"
            aria-label="Fermer le détail"
            hasArrow
            placement="left"
          >
            <span>
              <RiCloseCircleLine />
            </span>
          </Tooltip>
        </Button>
      </Flex>
      <Box p={4} pt={0} overflow="auto">
        <RightPageComponent key={number} {...pageProps} />
      </Box>
    </Flex>
  );
}
