import { useCurrentHotel } from '../../common/hooks/currentHotel';
import Link from 'next/link';

export default function CurrentHotelLink(props) {
  const hotel = useCurrentHotel();

  return (
    <Link href={`/${hotel?.slug}${props.href}`} passHref>
      {props.children}
    </Link>
  );
}
